import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";

import styles from "./index.module.css";
import { Button, TextField, Box, Typography, Grid } from "@mui/material";
import { v4 as uuidv4 } from "uuid"; // For unique keys
import LevelCard from "../../components/LevelCard";
import AddLevelCard from "../../components/AddLevelCard";
import CustomModal from "../../components/MachineList/CustomModal";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Level {
  id: string;
  points: string;
}
const Levels: React.FC = () => {
  const [isEditLevel, setIsEditLevel] = useState(false);
  const [currentLevel, setCurrentLevel] = useState<Level>({
    id: "",
    points: "",
  });
  const [levels, setLevels] = useState<Level[]>([]);

  const handleAddLevel = () => {
    setLevels([...levels, { id: uuidv4(), points: "1000" }]);
  };
  const handleRemoveLevel = (id: string) => {
    setLevels(levels.filter((level) => level.id !== id));
  };
  const handlePointsChange = (id: string, value: string) => {
    setCurrentLevel({
      ...currentLevel,
      points: value,
    });
  };

  const handleEditLevel = () => {
    if (Number(currentLevel.points) < 0 || currentLevel.points.length <= 0) {
      toast.error("Please Enter Valid Input", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
      });
      return;
    }
    setLevels(
      levels.map((level) =>
        level.id === currentLevel.id
          ? { ...level, points: currentLevel.points }
          : level
      )
    );
    setIsEditLevel(false);
  };
  useEffect(() => {
    if (!isEditLevel) {
      setCurrentLevel({
        id: "",
        points: "",
      });
    }
  }, [isEditLevel]);
  return (
    <Layout>
      <Box className={styles.container}>
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "600",
              fontFamily: "Poppins",
              textAlign: {
                xs: "center",
                sm: "start",
              },
            }}
          >
            Levels
          </Typography>

          {/* Level Cards Display */}
          <Box className={styles.cardsBox}>
            {levels.map((level, index) => (
              <LevelCard
                key={index}
                setCurrentLevel={setCurrentLevel}
                setIsEditLevel={setIsEditLevel}
                handleRemoveLevel={handleRemoveLevel}
                level={level}
                index={levels.indexOf(level) + 1}
              />
            ))}

            <AddLevelCard handleAddLevel={handleAddLevel} />
          </Box>
        </Box>
        <CustomModal open={isEditLevel} setOpen={setIsEditLevel} padding={5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" color={"#E0FD03"} textAlign={"center"}>
              Edit Level
            </Typography>
            <TextField
              label="Points"
              name="points"
              value={currentLevel?.points}
              onChange={(e) => {
                handlePointsChange(currentLevel?.id, e.target.value);
              }}
              fullWidth
              margin="normal"
              required
              placeholder="100"
              type="number"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  backgroundColor: "black",
                  "& fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&:hover fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#e0fd03",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#e0fd03",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
            <Box
              mt={4}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: 3,
              }}
            >
              <Button
                onClick={() => setIsEditLevel(false)}
                variant="outlined"
                sx={{
                  paddingX: 8,
                  height: 40,
                  borderRadius: 10,
                  color: "#E0FD03",
                  borderColor: "#E0FD03",
                  "&:hover": {
                    backgroundColor: "#e8ff3c",
                    color: "black",
                    borderColor: "black",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleEditLevel}
                variant="contained"
                sx={{
                  paddingX: 8,
                  height: 40,
                  borderRadius: 10,
                  backgroundColor: "#E0FD03",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#e8ff3c",
                    color: "black",
                  },
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </CustomModal>
      </Box>
    </Layout>
  );
};

export default Levels;
