import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import LoginPage from "./views/Login/LoginPage";
import { AdminProvider } from "./provider/AdminContext";
import TransactionHistory from "./views/TransactionHistory";
import Levels from "./views/Levels";
import { HttpClient } from "./api/axoisInstance";
import "react-toastify/dist/ReactToastify.css";
import { ProtectedRoute } from "./common_component/Protected";
import { PublicRoute } from "./common_component/PublicRoute";
HttpClient.setAxiosDefaultConfig();
function App() {
  return (
    <AdminProvider>
      <Router>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/transactions" element={<TransactionHistory />} />
            <Route path="/levels" element={<Levels />} />
          </Route>
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>
        </Routes>
      </Router>
    </AdminProvider>
  );
}

export default App;
