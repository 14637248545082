import { Box, Modal } from "@mui/material";
import { PropsWithChildren } from "react";
import styles from "./index.module.css";
interface Props extends PropsWithChildren {
  open: boolean;
  setOpen: (value: boolean) => void;
  width?: string;
  padding?: number;
}

export default function CustomModal(props: Props) {
  const { children, width, open, setOpen, padding } = props;

  return (
    <Modal
      open={open}
      onClose={() => setOpen(!open)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.container} style={{ width: `${width}` }}>
        {children}
      </Box>
    </Modal>
  );
}
