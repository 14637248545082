import { AxiosResponse } from "axios";
import { buildQueryString, QueryParams } from "../utils/functions"
import { HttpClient } from "./axoisInstance";


const loginApi = (data: { email: string, password: string }) => {
    const apiData = {
        url: `/admin/login`,
        method: "POST",
        data
    }
    return HttpClient.custom(apiData)
}

const deleteMachineApi = (selectedMachineId: string) => {
    const apiData = {
        url: `/machine/${selectedMachineId}`,
        method: "DELETE",
    }
    return HttpClient.custom(apiData)
}

const getMachinesApi = (params: QueryParams): Promise<AxiosResponse<MachineListResponse>> => {
    const queryString = buildQueryString(params);
    const apiData = {
        url: `/machine${queryString}`,
        method: "GET",
    }
    return HttpClient.custom(apiData)
}

const addMachinApi = (data: NewMachine): Promise<AxiosResponse<AddMachineResponse>> => {
    const apiData = {
        url: `/machine`,
        method: "POST",
        data
    }
    return HttpClient.custom(apiData)
}

const updateMachineApi = (selectedMachineId: string, data: NewMachine): Promise<AxiosResponse<AddMachineResponse>> => {
    const apiData = {
        url: `/machine/${selectedMachineId}`,
        method: "PUT",
        data
    }
    return HttpClient.custom(apiData)
}

const getTransactionsApi = (params: QueryParams): Promise<AxiosResponse<TransactionListResponse>> => {
    const queryString = buildQueryString(params);
    const apiData = {
        url: `/transactions${queryString}`,
        method: "GET",
    }
    return HttpClient.custom(apiData)
}



export { getMachinesApi, loginApi, deleteMachineApi, getTransactionsApi, addMachinApi, updateMachineApi }