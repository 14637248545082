import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as EditIcon } from "../../assets/levelEdit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/levelDelete.svg";
import React from "react";
import styles from "./index.module.css";
interface Level {
  id: string;
  points: string;
}
interface Props {
  index: number;
  level: Level;
  setIsEditLevel: (v: boolean) => void;
  handleRemoveLevel: (id: string) => void;
  setCurrentLevel: (level: Level) => void;
}
const LevelCard: React.FC<Props> = ({
  index,
  level,
  setIsEditLevel,
  handleRemoveLevel,
  setCurrentLevel,
}) => {
  const handleEdit = () => {
    setIsEditLevel(true);
    setCurrentLevel(level);
  };
  return (
    <Box>
      <Box className={styles.card}>
        <Typography className={styles.text}>Level</Typography>
        <Typography
          className={styles.text}
          sx={{
            color: "white !important",
            fontSize: "2.5rem",
            fontWeight: "700",
          }}
        >
          {index}
        </Typography>
        <Typography className={styles.text}>{level.points} Points</Typography>
        <Box
          sx={{
            position: "relative",
            bottom: "-14px",
          }}
        >
          <IconButton onClick={handleEdit}>
            <EditIcon height={20} width={20} />
          </IconButton>
          <IconButton onClick={() => handleRemoveLevel(level.id)}>
            <DeleteIcon height={20} width={20} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default LevelCard;
