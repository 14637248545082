import { Slide, toast, ToastOptions } from "react-toastify";
export interface QueryParams {
  [key: string]: string | number | undefined;
}
interface ToastifyProps {
  type: ToastifyType.SUCCESS | ToastifyType.ERROR | ToastifyType.WARNING | ToastifyType.INFO;
  options?: ToastOptions;
  message: string
}

export enum ToastifyType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

let defaultToastConfig: ToastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: "dark",
  transition: Slide,
}

export const toastify = (props: ToastifyProps): void => {
  let { type = ToastifyType.SUCCESS, options = {}, message } = props
  switch (type) {
    case ToastifyType.SUCCESS:
      toast.success(message, { ...defaultToastConfig, ...options });
      break;
    case ToastifyType.ERROR:
      toast.error(message, { ...defaultToastConfig, ...options });
      break;
    case ToastifyType.INFO:
      toast.info(message, { ...defaultToastConfig, ...options });
      break;
    case ToastifyType.WARNING:
      toast.warning(message, { ...defaultToastConfig, ...options });
      break;
    default:
      toast(message, { ...defaultToastConfig, ...options }); 
  }
}


export const buildQueryString = (params: QueryParams): string => {
  const queryString = Object.entries(params)
    .filter(([_, value]) => value !== undefined) // Filter out undefined values
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
    .join('&');

  return queryString ? `?${queryString}` : '';
};

export const logout = (): void => {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
}